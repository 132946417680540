@if ({layout: layout$ | async, scrolled: scrolled$ | async}; as ctx) {
  <div>
    <div
      class="h-full"
      [ngClass]="{
        'bg-president-50': ctx.layout?.backgroundColour === 'blue',
        'layout-navbar--transparent': ctx.layout?.transparentNavbar,
        'layout-navbar--scrolled': ctx.scrolled === 'scrolling'
      }"
    >
      @if ({banner: announcementBanner$ | async}; as ctx2) {
        @if (ctx2.banner && !ctx.layout?.hideNavbar && !ctx.layout?.navAdminMode) {
          <bgo-announcement-banner
            [ngClass]="{'hidden sm:block': ctx.layout?.hideNavbarOnMobile}"
            [id]="ctx2.banner.id!"
            [text]="ctx2.banner.textMessage!"
            [linkText]="ctx2.banner.linkText!"
            [link]="ctx2.banner.link!"
          ></bgo-announcement-banner>
        }
      }
      @if (!ctx.layout?.hideNavbar && !ctx.layout?.navAdminMode) {
        <bgo-parent-brand-banner
          [ngClass]="{'!hidden sm:block': ctx.layout?.hideNavbarOnMobile}"
        ></bgo-parent-brand-banner>
      }
      <header class="sticky top-0 z-40" #header>
        @if (!ctx.layout?.hideNavbar) {
          <bgo-navbar [ngClass]="{'hidden sm:block': ctx.layout?.hideNavbarOnMobile}" />
        }
      </header>
      <main
        [ngClass]="{
          'h-layout v-layout': !ctx.layout?.optOutOfLayout && !ctx.layout?.navAdminMode
        }"
        [class.content]="!ctx.layout?.showMinimalistFooter"
        [class.content-min-footer]="ctx.layout?.showMinimalistFooter"
      >
        <div
          [ngClass]="{
            '-mt-navbar-thin-with-banner sm:-mt-navbar-with-banner':
              ctx.layout?.transparentNavbar && !ctx.layout?.hideNavbar
          }"
        >
          <router-outlet bgoBroadcastActivateEvents></router-outlet>
        </div>
      </main>
      <div class="invisible h-[1px]" #shadow>
        <!--        hidden div to fix footer rendering issues on some viewports-->
      </div>
      @defer (on hover(header); prefetch on idle) {
        <bgo-navbar-side-menu></bgo-navbar-side-menu>
      }
      @defer (on viewport(shadow)) {
        <bgo-footer />
      } @placeholder {
        <div></div>
      }
    </div>
  </div>
}
@if (structuredData$ | async; as json) {
  <bgo-json-ld [json]="json"></bgo-json-ld>
}
